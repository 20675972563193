import fetchRetry from 'fetch-retry';

import { getDeviceId } from '../../lib/common/device';
import { UserEvent, getDeduplicatedTimestampInSeconds } from '../../lib/events/userEvents';

const DEV_STACK_EVENT_LOGGING_URL = 'https://ttwgkhkd82.execute-api.eu-west-1.amazonaws.com/ingest-user-events';
const PROD_STACK_EVENT_LOGGING_URL = 'https://5lcadbj7za.execute-api.eu-west-1.amazonaws.com/ingest-user-events';

type LogUserEventInput = {
    type: string;
    active?: boolean;
    payload?: string;
    timestamp?: number;
};

export async function logUserEventWithoutAuthentication(userId: string, event: UserEvent, shouldUseDevStack?: boolean) {
    console.log(`API call: logging ${event.type} event...`);
    const timestamp = getDeduplicatedTimestampInSeconds();
    const payload = { ...(event.payload ?? {}), platform: 'webApp', deviceId: getDeviceId() };
    const stringifiedPayload = JSON.stringify(payload);
    const logUserEventInput = {
        ...event,
        payload: stringifiedPayload,
        active: true,
        timestamp,
    };
    return await logUserEventThroughRestApi(logUserEventInput, userId, shouldUseDevStack);
}

const fetchWithRetry = fetchRetry(fetch);

async function logUserEventThroughRestApi(
    event: LogUserEventInput,
    userId: string,
    shouldUseDevStack?: boolean
): Promise<{ status: 'success' | 'failure' }> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    try {
        const response = await fetchWithRetry(
            shouldUseDevStack ? DEV_STACK_EVENT_LOGGING_URL : PROD_STACK_EVENT_LOGGING_URL,
            {
                method: 'POST',
                headers,
                body: JSON.stringify({ userId, events: [event] }),
                retryDelay: (attempt) => {
                    if (attempt === 1) return 3 * 1000; // 3 seconds
                    else return 10 * 1000; // 10 seconds
                },
                retries: 2,
            }
        );
        if (response.ok) return { status: 'success' };
        else {
            console.error('Failed to log user event:', response.status, response.statusText);
            return { status: 'failure' };
        }
    } catch (error) {
        console.error(error);
        return { status: 'failure' };
    }
}
