import { FetchPolicy, useReactiveVar } from '@apollo/react-hooks';

import { UserState } from '../../api/graphql/fragments/states';
import { AppFeature, FeatureAndStateQueryResponse, featureAndStateQuery } from '../../api/graphql/queries/featuresAndStates';
import { featureMapVar } from '../../api/graphql/reactiveVariables';
import { ApolloClient } from '../../api/graphql/client';

const mongoCompiler = require('mongo-query-compiler');

export enum Feature {
    useNewOfferConditionStyleInWebApp = 'use-new-offer-condition-style-in-web-app',
    useCashbackRatesMergingInWebApp = 'use-cashback-rates-merging-in-web-app',
    giftCardsAtRedemption = 'gift-cards-at-redemption',
    intercomInWebApp = 'intercom-in-web-app',
}

export type FeatureMap = { [featureId: string]: boolean };

export function useFeatureMap(): FeatureMap | undefined {
    return useReactiveVar(featureMapVar) ?? undefined;
}

export async function updateFeatureMapVar(client: ApolloClient) {
    const featureMap = await fetchFeatureMap(client);
    featureMapVar(featureMap);
}

async function fetchFeatureMap(client: ApolloClient, fetchPolicy: FetchPolicy = 'network-only') {
    const featureAndStateQueryResponse = await client.query<FeatureAndStateQueryResponse>({
        query: featureAndStateQuery,
        fetchPolicy,
    });
    return buildFeatureMapFromQueryResponse(featureAndStateQueryResponse.data);
}

function buildFeatureMapFromQueryResponse(data: FeatureAndStateQueryResponse | undefined): FeatureMap {
    let featureMap: FeatureMap = {};
    if (data && data.user && data.user.states && data.appFeatures && data.appFeatures.items)
        featureMap = buildFeatureMap(data.user.states, data.appFeatures.items);
    return featureMap;
}

function buildFeatureMap(userStates: UserState[], appFeatures: AppFeature[]): FeatureMap {
    const parsedUserStatesMap = buildParsedUserStatesMap(userStates);
    const userFeatureMap: FeatureMap = {};
    for (const feature of appFeatures) {
        const parsedCondition = JSON.parse(feature.condition);
        const featureStatus = mongoCompiler(parsedCondition)(parsedUserStatesMap);
        userFeatureMap[feature.featureId] = featureStatus;
    }
    return userFeatureMap;
}

type ParsedUserState = {
    stateKey: string;
    value: any;
};

type ParsedUserStatesMap = { [stateKey: string]: any };

function buildParsedUserStatesMap(userStates: UserState[]): ParsedUserStatesMap {
    const parsedUserStates: ParsedUserState[] = userStates.map((userState) => ({
        stateKey: userState.stateKey,
        value: userState.value ? JSON.parse(userState.value) : undefined,
    }));
    const parsedUserStatesMap: ParsedUserStatesMap = {};
    for (const userState of parsedUserStates) parsedUserStatesMap[userState.stateKey] = userState.value;
    return parsedUserStatesMap;
}
