import React from 'react';
import { Platform } from 'react-native';
import { useApolloClient } from '@apollo/react-hooks';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import Intercom, { update, shutdown, onShow, onHide } from '@intercom/messenger-js-sdk';
import { MinimumBootArgs } from '@intercom/messenger-js-sdk/dist/types';

import { ApolloClient } from '../../api/graphql/client';
import {
    intercomUserHashQuery,
    IntercomUserHashQueryResponse,
    IntercomUserHashQueryVariables,
} from '../../api/graphql/queries/intercom';
import { logAmplitudeEvent } from '../events/amplitudeEvents';
import { logUserEventUtil } from '../events/userEvents';
import { Feature, FeatureMap, useFeatureMap } from '../features/buildFeatureMap';
import { getHelpCenterUrl, getRegion, Region } from '../auth/region';
import { checkIsSignedIn, CognitoUserAttributeName, getCognitoUserAttributes, getUserId } from './cognito';
import { getDevStackMode } from './devStackMode';

const INTERCOM_WORKSPACE_ID = 'a9u0qjwm';
const INTERCOM_API_BASE = 'https://api-iam.eu.intercom.io';
const INTERCOM_HOSTING_REGION = 'eu';

const INTERCOM_MINIMUM_BOOT_ARGUMENTS: MinimumBootArgs = {
    app_id: INTERCOM_WORKSPACE_ID,
    api_base: INTERCOM_API_BASE,
    region: INTERCOM_HOSTING_REGION,
};

interface IntercomUserAttributes {
    user_id: string;
    user_hash: string; // Used for identity verification
    email?: string;
    name?: string;
    language_override?: string;
    is_dev_stack?: boolean;
    user_app_region?: Region;
}

export function useHandleIntercomWidgetDisplay() {
    /**
     * We noticed that when featureMap changes, it does not necessarily re-trigger the effect.
     * This issue seems tracked on Apollo's side (see https://github.com/apollographql/apollo-client/issues/8681).
     * To work around this issue, we use a boolean state to track if the featureMap was loaded and re-trigger the effect.
     **/
    const [featureMapLoaded, setFeatureMapLoaded] = React.useState(false);
    const featureMap = useFeatureMap();
    const apolloClient = useApolloClient();
    if (!featureMapLoaded && featureMap) setFeatureMapLoaded(true);
    React.useEffect(() => {
        handleIntercomWidgetDisplay(featureMap, apolloClient);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apolloClient, featureMapLoaded]);
}

/**
 * We use this variable to prevent having multiple onShow and onHide listeners in parallel.
 */
let areEventListenersAdded = false;

export async function handleIntercomWidgetDisplay(
    featureMap: FeatureMap | undefined,
    apolloClient: ApolloClient | undefined
) {
    if (apolloClient) {
        const userId = getUserId();
        if (checkIsSignedIn() && !!userId && !!featureMap?.[Feature.intercomInWebApp]) {
            const userAttributes = await buildUserAttributes(userId, apolloClient);
            const isIntercomInitialized = checkIsIntercomInitialized();
            bootIntercom(userAttributes);
            if (!isIntercomInitialized) logLoggedIntoIntercomEvents(apolloClient);
            if (!areEventListenersAdded) {
                onShow(() => logOnShowIntercomSpaceEvent(apolloClient));
                onHide(() => logOnHideIntercomSpaceEvent(apolloClient));
                areEventListenersAdded = true;
            }
        } else shutdownIntercom();
    } else shutdownIntercom();
}

async function buildUserAttributes(userId: string, apolloClient: ApolloClient): Promise<IntercomUserAttributes> {
    const isDevStack = getDevStackMode();
    const userRegion = getRegion();
    const cognitoUserAttributes: CognitoUserAttribute[] | null = await getCognitoUserAttributes();
    let userEmail: string | undefined;
    let userName: string | undefined;
    if (cognitoUserAttributes) {
        for (const attribute of cognitoUserAttributes) {
            if (attribute.getName() === CognitoUserAttributeName.email) userEmail = attribute.getValue();
            if (attribute.getName() === CognitoUserAttributeName.givenName) userName = attribute.getValue();
        }
    }
    return {
        user_id: userId,
        user_hash: await getIntercomUserHash(apolloClient),
        email: userEmail,
        name: userName,
        language_override: userRegion === Region.FR ? 'fr' : 'en',
        is_dev_stack: isDevStack,
        user_app_region: userRegion,
    };
}

/** This user hash is used by Intercom for identity verification (see https://www.intercom.com/help/en/articles/7946878-what-is-identity-verification) */
async function getIntercomUserHash(apolloClient: ApolloClient): Promise<string> {
    const queryResponse = await apolloClient.query<IntercomUserHashQueryResponse, IntercomUserHashQueryVariables>({
        query: intercomUserHashQuery,
        variables: { platform: Platform.OS },
        fetchPolicy: 'network-only',
    });
    return queryResponse.data.user.intercomUserHash;
}

/**
 * When Intercom first initializes its Messenger, it will store an entry in the local storage.
 * We thus use this entry as a proxy to know if Intercom was already initialized.
 */
function checkIsIntercomInitialized(): boolean {
    for (let i = 0; 1; i++) {
        const key = localStorage.key(i);
        if (!key) break;
        else {
            const match = key.match(`intercom.intercom-state-${INTERCOM_WORKSPACE_ID}`);
            if (match) return true;
        }
    }
    return false;
}

function bootIntercom(userAttributes: IntercomUserAttributes) {
    Intercom({ ...INTERCOM_MINIMUM_BOOT_ARGUMENTS, ...userAttributes });
}

function logLoggedIntoIntercomEvents(client: ApolloClient) {
    logUserEventUtil(client, { type: 'loggedIntoIntercom' });
    logAmplitudeEvent({ name: 'Intercom - Logged In' });
}

function logOnShowIntercomSpaceEvent(client: ApolloClient) {
    logUserEventUtil(client, { type: 'openedIntercomSpace' });
    logAmplitudeEvent({ name: 'Intercom - Opened Space' });
}

function logOnHideIntercomSpaceEvent(client: ApolloClient) {
    logUserEventUtil(client, { type: 'closedIntercomSpace' });
    logAmplitudeEvent({ name: 'Intercom - Closed Space' });
}

function shutdownIntercom() {
    shutdown();
}

export async function updateUserOnIntercom(
    userAttributesToUpdate: Partial<IntercomUserAttributes>,
    apolloClient: ApolloClient
) {
    update(userAttributesToUpdate);
    logUserEventUtil(apolloClient, { type: 'profileUpdatedOnIntercom' });
    logAmplitudeEvent({ name: 'Intercom - Profile Updated' });
}

export function logoutUserFromIntercom(client: ApolloClient) {
    shutdownIntercom();
    logUserEventUtil(client, { type: 'loggedOutOfIntercom' });
    logAmplitudeEvent({ name: 'Intercom - Logged Out' });
}

export function accessHelpCenter(featureMap: FeatureMap | undefined) {
    if (!!featureMap?.[Feature.intercomInWebApp]) window.open(getHelpCenterUrl(true));
    else window.open(getHelpCenterUrl(false));
}
