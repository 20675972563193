import * as React from 'react';
import { Image, StyleSheet, Text, View, ViewStyle } from 'react-native';

import { newDesignSystemFontStyles, normalizeHeight } from './Styles';
import { getLocalizedTexts } from '../../../Locales';

const logo = { uri: '/assets/images/logos/lightning-rounded.svg' };
const warningIcon = { uri: '/assets/images/icons/inline-warning.svg' };
const numberIcons = {
    1: { uri: '/assets/images/icons/number-1.svg' },
    2: { uri: '/assets/images/icons/number-2.svg' },
    3: { uri: '/assets/images/icons/number-3.svg' },
    4: { uri: '/assets/images/icons/number-4.svg' },
};
const switchOnIcon = { uri: '/assets/images/icons/switch-on.svg' };
const extensionPuzzlePieceIcon = { uri: '/assets/images/icons/extension-puzzle-piece.svg' };

export function InstructionClickSafariSettings({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.instructionsClickSafariSettings;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part1}</Text>
                <View style={styles.containerInstructionsDetail}>
                    <Text>
                        <Text style={styles.textContainerInstructionSmall}>A</Text>
                        <Text style={styles.textContainerInstruction}>A</Text>
                    </Text>
                </View>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part2}</Text>
            </View>
        </View>
    );
}

export function InstructionManageExtensions({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const texts =
        getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.activateStep.instructionsManageExtensions;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part1}</Text>
                <View style={styles.containerInstructionsDetail}>
                    <Text style={styles.textContainerInstruction}>{texts.part2}</Text>
                </View>
                <Image source={extensionPuzzlePieceIcon} style={styles.imageExtensionPuzzlePieceIcon} />
            </View>
        </View>
    );
}

export function InstructionActivateJokoExtension({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const text =
        getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.activateStep.instructionsActivateJokoExtension;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={[styles.containerInstructionsDetails, { gap: 8 } as ViewStyle]}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{text}</Text>
                <View style={styles.containerInstructionIcons}>
                    <Image source={logo} style={styles.imageInline} />
                    <Image source={switchOnIcon} style={styles.imageSwitchOnIcon} />
                </View>
            </View>
        </View>
    );
}

export function InstructionClickBanner({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.authorizeStep.instructionsClickBanner;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text>
                    <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part1}</Text>
                    <Text style={newDesignSystemFontStyles.paragraphSmallBold}>{texts.part2}</Text>
                </Text>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part3}</Text>
            </View>
        </View>
    );
}

export function InstructionClickExtensionWarningIcon({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const texts =
        getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.authorizeStep
            .instructionsClickExtensionWarningIcon;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part1}</Text>
                <Image source={warningIcon} style={styles.warningInline} />
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part2}</Text>
            </View>
        </View>
    );
}

export function InstructionAllowAlways({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.authorizeStep.instructionsAlwaysAllow;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part1}</Text>
                <View style={styles.containerInstructionsDetail}>
                    <Text style={styles.textContainerInstruction}>{texts.part2}</Text>
                </View>
            </View>
        </View>
    );
}

export function InstructionAllowOnAllWebsites({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const texts =
        getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.authorizeStep.instructionsAllowOnAllWebsites;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part1}</Text>
                <View style={styles.containerInstructionsDetail}>
                    <Text style={styles.textContainerInstruction}>{texts.part2}</Text>
                </View>
            </View>
        </View>
    );
}

export function InstructionSystemSettingsClickButton({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const text =
        getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.missingAuthorization
            .instructionsSystemSettingsClickButton;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{text}</Text>
            </View>
        </View>
    );
}

export function InstructionSystemSettingsOtherWebsites({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const texts =
        getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.missingAuthorization
            .instructionsSystemSettingsOtherWebsites;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part1}</Text>
                <View style={styles.containerInstructionsDetail}>
                    <Text style={styles.textContainerInstruction}>{texts.part2}</Text>
                </View>
            </View>
        </View>
    );
}

export function InstructionSystemSettingsAllow({ itemNumber }: { itemNumber: 1 | 2 | 3 | 4 }) {
    const texts =
        getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.missingAuthorization
            .instructionsSystemSettingsAllow;
    return (
        <View style={styles.containerInstruction}>
            <Image source={numberIcons[itemNumber]} style={styles.imageInstructionIcon} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.part1}</Text>
                <View style={styles.containerInstructionsDetail}>
                    <Text style={styles.textContainerInstruction}>{texts.part2}</Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    containerInstruction: {
        height: normalizeHeight(25),
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    containerInstructionsDetails: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 0,
    },
    containerInstructionsDetail: {
        flexDirection: 'row',
        paddingHorizontal: 8,
        paddingVertical: 3,
        alignItems: 'center',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.8)',
    },
    containerInstructionIcons: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 4,
    },
    textContainerInstruction: {
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: 14 * 1.4,
        color: 'rgba(0, 0, 0, 0.8)',
    },
    textContainerInstructionSmall: {
        fontWeight: 'bold',
        fontSize: 10,
        lineHeight: 14 * 1.4,
        color: 'rgba(0, 0, 0, 0.8)',
    },
    imageInstructionIcon: {
        width: normalizeHeight(20),
        height: normalizeHeight(20),
    },
    imageInline: {
        width: normalizeHeight(16),
        height: normalizeHeight(16),
    },
    warningInline: {
        width: normalizeHeight(20),
        height: normalizeHeight(20),
    },
    imageSwitchOnIcon: {
        height: normalizeHeight(16),
        width: normalizeHeight(26.323),
        resizeMode: 'stretch',
    },
    imageExtensionPuzzlePieceIcon: {
        height: normalizeHeight(20),
        width: normalizeHeight(20),
    },
});
