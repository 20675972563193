import * as React from 'react';
import { StyleSheet, View, Text, Image, ActivityIndicator } from 'react-native';
import { useLocation } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import { font } from '../../style/text';
import color from '../../style/color';
import { logUserEventWithoutAuthentication } from '../../api/rest/events';
import { FloaTermsOfServices } from '../../components/home/bnpl/FloaTermsOfService';

const logo = { uri: '/assets/images/logos/logo-with-title.svg' };
const FLOA_PAYMENT_LIBRARY_DEV = 'https://payment.integration-cb4x.fr/dist/js/paymentHub.min.js';
const FLOA_PAYMENT_LIBRARY_PROD = 'https://payment.cb4x.fr/dist/js/paymentHub.min.js';

/**
 * This page is used from our extensions for the Floa payment collection
 * Extensions will pass in the parameters required to render the Floa iframe
 * Unlike the mobile app, the JS code is not injected into the page
 * but instead directly embedded into it, cf below
 *
 * Contrary to FloaIframeWithUrlParamsPage, this page is intended to be opened
 *  in a new window as a standalone
 * */
function FloaPaymentPageWithUrlParams() {
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const userId = urlSearchParams.get('userId');
    const paymentSessionId = urlSearchParams.get('paymentSessionId');
    const base64EncodedPaymentSessionUrl = urlSearchParams.get('base64EncodedPaymentSessionUrl');
    const stackMode = urlSearchParams.get('stackMode');
    const paymentSessionUrl =
        typeof base64EncodedPaymentSessionUrl === 'string'
            ? Buffer.from(base64EncodedPaymentSessionUrl, 'base64').toString('binary')
            : undefined;
    const paymentHubUrl = stackMode === 'dev' ? FLOA_PAYMENT_LIBRARY_DEV : FLOA_PAYMENT_LIBRARY_PROD;
    const [isPaymentComplete, setIsPaymentComplete] = React.useState(false);
    useLoadFloaLibraryAndRenderIframe(
        userId,
        paymentHubUrl,
        paymentSessionId || undefined,
        paymentSessionUrl,
        setIsPaymentComplete,
        stackMode
    );
    const texts = getLocalizedTexts().home.floaPaymentPage;
    return (
        <View style={styles.container}>
            <View style={styles.containerLogo}>
                <Image source={logo} style={styles.imageLogo} />
            </View>
            {isPaymentComplete ? (
                <>
                    <ActivityIndicator size="large" color={color.black} />
                    <Text style={styles.textSubtitle}>{texts.autoClose}</Text>
                </>
            ) : (
                <>
                    <Text style={styles.textTitle}>{texts.title}</Text>
                    <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
                    <FloaIframe />
                    <View style={styles.containerTerms}>
                        <FloaTermsOfServices {...{ buttonName: texts.buttonName }} />
                    </View>
                </>
            )}
        </View>
    );
}

export default FloaPaymentPageWithUrlParams;

function useLoadFloaLibraryAndRenderIframe(
    userId: string | null,
    paymentHubUrl: string,
    paymentSessionId: string | undefined,
    paymentSessionUrl: string | undefined,
    setIsPaymentComplete: (isPaymentComplete: boolean) => void,
    stackMode: string | null
) {
    React.useEffect(() => {
        if (!paymentHubUrl || !paymentSessionId || !paymentSessionUrl) return;
        function loadFloaScript() {
            return new Promise<void>((resolve, reject) => {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = paymentHubUrl;
                script.onload = () => resolve();
                script.onerror = () => reject();
                document.body.appendChild(script);
            });
        }
        function loadFloaPaymentIframe() {
            const hubOptions = {
                paymentSessionId: paymentSessionId,
                paymentSessionUrl: paymentSessionUrl,
                placeHolderSelector: '#iframeDiv',
                paymentCompleted: () => {
                    if (userId)
                        logUserEventWithoutAuthentication(
                            userId,
                            { type: 'floaIframePaymentCompleted', payload: { paymentSessionId } },
                            stackMode === 'dev'
                        );
                    setIsPaymentComplete(true);
                },
                /** Callback for when a 3DS redirection is needed. This is notably the case when
                 * the browser does not support third party cookies during the Floa payment
                 */
                paymentRedirect: (event: any) => {
                    if (userId)
                        logUserEventWithoutAuthentication(
                            userId,
                            { type: 'floaIframePaymentRedirected', payload: { paymentSessionId } },
                            stackMode === 'dev'
                        );
                    window.location.replace(event.redirectUrl);
                },
                /** If third party cookies are supported, we let the user completes 3DS in the Floa iframe */
                isRedirectFullpageRequired: false,
                style: { height: '400px', width: '350px' },
            };
            // @ts-ignore PaymentHub is defined in the Floa script
            const iframeComponent = new PaymentHub.IframeComponent(hubOptions);
            iframeComponent.init();
        }
        loadFloaScript()
            .then(() => loadFloaPaymentIframe())
            .catch(console.error);
    }, [userId, stackMode, paymentHubUrl, paymentSessionId, paymentSessionUrl, setIsPaymentComplete]);
}

function FloaIframe() {
    return <div id="iframeDiv"></div>;
}

const styles = StyleSheet.create({
    container: {
        height: '100vh',
        flex: 1,
        alignItems: 'center',
    },
    containerLogo: {
        marginTop: 30,
        marginBottom: 110,
    },
    containerTerms: {
        width: 330,
    },
    imageLogo: {
        width: 114,
        height: 55,
        resizeMode: 'contain',
    },
    textTitle: {
        fontFamily: font.ambitBold,
        fontWeight: '900',
        fontSize: 22,
        lineHeight: 34,
        textAlign: 'center',
        color: color.black,
    },
    textSubtitle: {
        fontFamily: font.ambitRegular,
        fontWeight: '600',
        fontSize: 16,
        lineHeight: 24,
        textAlign: 'center',
        color: color.black,
        marginTop: 15,
        width: 330,
    },
});
