const RETURN_TO_APP_URL = 'joko://';
const SYSTEM_SETTINGS_REDIRECTION = 'MobileSafariExtensionSystemSettingsRedirection/';
const PRIVACY_POLICY = 'PrivacyPolicy';
const TERMS_OF_SERVICE = 'TermsOfService';

export function returnToApp() {
    window.location.href = RETURN_TO_APP_URL;
}

export function returnToSystemSettings() {
    window.location.replace(RETURN_TO_APP_URL + SYSTEM_SETTINGS_REDIRECTION);
}

export function redirectToPrivacyPolicy() {
    const privacyPolicyUrl = RETURN_TO_APP_URL + PRIVACY_POLICY;
    window.location.href = privacyPolicyUrl;
}

export function redirectToTermsOfService() {
    const termsOfServiceUrl = RETURN_TO_APP_URL + TERMS_OF_SERVICE;
    window.location.href = termsOfServiceUrl;
}
