import * as React from 'react';
import { Modal, StyleSheet, View, Text, Image, Dimensions, ActivityIndicator, TouchableWithoutFeedback } from 'react-native';
import { useParams } from 'react-router';

import { formatCurrencyAmount, getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { redeemIOSDonationReward } from '../../api/rest/rewards';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';
import JokoLogoHeader from '../../components/public/JokoLogoHeader';
import { logUserEventWithoutAuthentication } from '../../api/rest/events';
import { logAmplitudeEventWithoutAuthentication } from '../../lib/events/amplitudeEvents';
import { RewardType } from '../../api/graphql/fragments/rewards';

const checkMarkGreenFilledIcon = { uri: '/assets/images/icons/check-mark-green-thin-filled.svg' };
const crossCircleRedFilledIcon = { uri: '/assets/images/icons/cross-circle-red.svg' };

export enum DonationRedeemState {
    toConfirm = 'toConfirm',
    success = 'success',
    error = 'error',
    cancelled = 'cancelled',
}

function IOSDonationPage() {
    const { userId, token, rewardId, rewardTitle, rewardValue, stackMode, rewardLogoUrl } = useParams<{
        userId: string;
        token: string;
        rewardId: string;
        rewardTitle: string;
        rewardValue: string;
        rewardLogoUrl: string;
        stackMode?: string;
    }>();
    // We expect the rewardValue to be a base64 encoded string but also parse it as a number for backwards compatibility
    const decodedRewardValue = React.useMemo(() => getDecodedRewardValue(rewardValue), [rewardValue]);
    const [donationRedeemState, setDonationRedeemState] = React.useState<DonationRedeemState>(
        decodedRewardValue !== null ? DonationRedeemState.toConfirm : DonationRedeemState.error
    );
    const shouldUseDevStack: boolean = stackMode === 'dev';
    return (
        <Modal transparent={true}>
            <TouchableWithoutFeedback>
                <View style={styles.container}>
                    <View>
                        <JokoLogoHeader />
                        {donationRedeemState === DonationRedeemState.toConfirm && decodedRewardValue !== null ? (
                            <>
                                <RewardInfo {...{ rewardLogoUrl, rewardTitle }} />
                                <AmountSection {...{ decodedRewardValue }} />
                            </>
                        ) : (
                            <RedeemResult {...{ donationRedeemState }} />
                        )}
                    </View>
                    {donationRedeemState === DonationRedeemState.toConfirm && decodedRewardValue !== null ? (
                        <RedeemButton
                            {...{ userId, rewardId, token, decodedRewardValue, shouldUseDevStack, setDonationRedeemState }}
                        />
                    ) : (
                        <RedirectToAppButton {...{ userId, shouldUseDevStack, rewardId, rewardTitle }} />
                    )}
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
}

export default IOSDonationPage;

function RewardInfo({ rewardLogoUrl, rewardTitle }: { rewardLogoUrl: string; rewardTitle: string }) {
    return (
        <View style={styles.containerRewardInfo}>
            <Logo {...{ rewardLogoUrl }} />
            <RewardTitle {...{ rewardTitle }} />
        </View>
    );
}

function Logo({ rewardLogoUrl }: { rewardLogoUrl: string }) {
    return (
        <View style={styles.containerLogo}>
            <Image style={styles.imageLogo} source={{ uri: decodeURIComponent(rewardLogoUrl) }} />
        </View>
    );
}

function RewardTitle({ rewardTitle }: { rewardTitle: string }) {
    return (
        <View style={styles.containerRewardTitle}>
            <Text style={styles.textSubtitle}>{decodeURIComponent(rewardTitle)}</Text>
        </View>
    );
}

function AmountSection({ decodedRewardValue }: { decodedRewardValue: number }) {
    const texts = getLocalizedTexts().home.rewards.iOSDonationRedeem.confirmation;
    return (
        <>
            <View style={styles.containerTitle}>
                <Text style={styles.textTitle}>{texts.donationAmount}</Text>
            </View>
            <View style={styles.containerTextInput}>
                <Text style={styles.textInput}>{formatCurrencyAmount(decodedRewardValue)}</Text>
            </View>
        </>
    );
}

function RedeemResult({ donationRedeemState }: { donationRedeemState: DonationRedeemState }) {
    const texts = getLocalizedTexts().home.rewards.iOSDonationRedeem;
    const resultTexts =
        donationRedeemState === DonationRedeemState.success
            ? texts.success
            : donationRedeemState === DonationRedeemState.cancelled
            ? texts.cancellation
            : texts.error;
    return (
        <View style={styles.containerContent}>
            {donationRedeemState === DonationRedeemState.success ? (
                <View style={styles.containerSuccessImage}>
                    <Image source={checkMarkGreenFilledIcon} style={{ width: 32, height: 32, resizeMode: 'contain' }} />
                </View>
            ) : (
                <View style={styles.containerErrorImage}>
                    <Image source={crossCircleRedFilledIcon} style={{ width: 32, height: 32, resizeMode: 'contain' }} />
                </View>
            )}
            <View style={styles.containerTitle}>
                <Text style={styles.textTitle}>{resultTexts.title}</Text>
            </View>
            <Text style={styles.textSubtitle}>{resultTexts.text1}</Text>
            <Text style={[styles.textSubtitle, { marginTop: 8 }]}>{resultTexts.text2}</Text>
        </View>
    );
}

function RedeemButton({
    userId,
    rewardId,
    token,
    decodedRewardValue,
    shouldUseDevStack,
    setDonationRedeemState,
}: {
    userId: string;
    rewardId: string;
    token: string;
    decodedRewardValue: number;
    shouldUseDevStack: boolean;
    setDonationRedeemState: (value: DonationRedeemState) => void;
}) {
    const texts = getLocalizedTexts().home.rewards.iOSDonationRedeem.confirmation;
    const [isRedeeming, setIsRedeeming] = React.useState<boolean>(false);
    const onPressConfirm = async () => {
        if (!isRedeeming) {
            const payload = { rewardId: rewardId, type: RewardType.donation, points: Number(decodedRewardValue) };
            setIsRedeeming(true);
            setDonationRedeemState(
                await redeemIOSDonationReward(userId, rewardId, token, decodedRewardValue, shouldUseDevStack)
            );
            setIsRedeeming(false);
            logUserEventWithoutAuthentication(userId, { type: 'confirmedRewardRedeem', payload }, shouldUseDevStack);
            logAmplitudeEventWithoutAuthentication({ name: 'Rewards - Confirmed Redeem', properties: payload }, { userId });
        }
    };
    return (
        <View style={styles.containerButtons}>
            <HoverableTouchableOpacity style={styles.containerRedeemButton} onPress={onPressConfirm}>
                {isRedeeming ? (
                    <ActivityIndicator color={color.white} />
                ) : (
                    <Text style={[styles.textButton, { color: color.white }]}>{texts.confirmButton}</Text>
                )}
            </HoverableTouchableOpacity>
        </View>
    );
}

const REDIRECT_TO_APP_TIMEOUT_IN_MS = 2000;

function RedirectToAppButton({
    userId,
    rewardId,
    rewardTitle,
    shouldUseDevStack,
}: {
    userId: string;
    rewardId: string;
    rewardTitle: string;
    shouldUseDevStack: boolean;
}) {
    const texts = getLocalizedTexts().home.rewards.iOSDonationRedeem.confirmation;
    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            window.location.href = 'joko://Profile/';
        }, REDIRECT_TO_APP_TIMEOUT_IN_MS);
        return () => clearTimeout(timeoutId);
    }, []);
    const onPressConfirm = async () => {
        const payload = { rewardId, rewardTitle };
        logUserEventWithoutAuthentication(userId, { type: 'clickedRedirectionToApp', payload }, shouldUseDevStack);
        logAmplitudeEventWithoutAuthentication(
            { name: 'Rewards - Clicked Redirection To App', properties: payload },
            { userId }
        );
        window.location.href = 'joko://Profile/';
    };
    return (
        <View style={styles.containerButtons}>
            <HoverableTouchableOpacity style={styles.containerRedeemButton} onPress={onPressConfirm}>
                <Text style={[styles.textButton, { color: color.white }]}>{texts.close}</Text>
            </HoverableTouchableOpacity>
        </View>
    );
}

function getDecodedRewardValue(input: string): number | null {
    if (!isNaN(parseFloat(input)) && isFinite(parseFloat(input))) {
        return parseFloat(input);
    }
    try {
        const decodedString = atob(input);
        const parsedNumber = parseFloat(decodedString);
        if (!isNaN(parsedNumber) && parsedNumber.toString() === decodedString.trim()) {
            return parsedNumber;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

const { height, width } = Dimensions.get('window');

const PADDING = 20;
const BUTTON_HEIGHT = 53;

const styles = StyleSheet.create({
    container: {
        height: height,
        width: width,
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerContent: {
        paddingHorizontal: PADDING,
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerTitle: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16,
        marginBottom: 14,
    },
    containerButtons: {
        width: width - 2 * PADDING,
        marginBottom: 15,
    },
    containerRedeemButton: {
        height: BUTTON_HEIGHT,
        borderRadius: 80,
        backgroundColor: color.black,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 35,
        marginTop: 10,
    },
    containerRewardInfo: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        marginTop: 10,
        backgroundColor: color.white,
    },
    containerLogo: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 88,
        height: 88,
        marginBottom: 10,
        borderRadius: 48,
        backgroundColor: color.linkWaterLight,
        borderWidth: 1,
        borderColor: color.linkWaterLight,
    },
    containerRewardTitle: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerSuccessImage: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 76,
        height: 76,
        marginTop: 10,
        borderRadius: 48,
        backgroundColor: color.snowyMint,
    },
    containerErrorImage: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 76,
        height: 76,
        marginTop: 10,
        borderRadius: 48,
        backgroundColor: color.pippin,
    },
    containerTextInput: {
        alignSelf: 'center',
        justifyContent: 'center',
        height: 67,
        paddingHorizontal: 24,
        paddingVertical: 16,
        borderRadius: 8,
        backgroundColor: color.alabaster,
    },
    imageLogo: {
        width: 76,
        height: 76,
        resizeMode: 'contain',
    },
    textInput: {
        fontFamily: font.ambitBold,
        fontSize: 32,
        textAlign: 'center',
        color: color.tundora,
    },
    textTitle: {
        fontFamily: font.ambitBold,
        fontSize: 24,
        color: color.black,
        textAlign: 'center',
    },
    textSubtitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.doveGray,
        textAlign: 'center',
    },
    textButton: {
        fontSize: 16,
        fontFamily: font.ambitBold,
    },
});
