import { Region } from '../../../lib/auth/region';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { Dimensions, StyleSheet, ViewStyle } from 'react-native';

export const FINGER_POINTING_UP_EMOJI = '👆';

// Given at https://www.figma.com/design/R9LfFiwSeE34SRsAx3pn1Y/%F0%9F%92%A0-Foundations?node-id=2-722&t=yL9GDmhWhsb3Tu9i-0
export const newDesignSystemFontStyles = StyleSheet.create({
    titleXL: {
        fontFamily: font.ambitBold,
        fontSize: 22,
        lineHeight: 22 * 1.2,
        letterSpacing: 22 * -0.01,
    },
    actionMedium: {
        fontFamily: font.ambitBold,
        fontSize: 16,
        lineHeight: 20,
    },
    actionMediumUnderline: {
        fontFamily: font.ambitBold,
        fontSize: 16,
        lineHeight: 20,
        textDecorationLine: 'underline',
    },
    actionSmall: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        lineHeight: 14 * 1.2,
    },
    displayMedium: {
        fontFamily: font.ambitBold,
        fontSize: 40,
        lineHeight: 40 * 1.1,
        letterSpacing: 40 * -0.02,
    },
    paragraphMedium: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        lineHeight: 16 * 1.2,
    },
    paragraphSmall: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        lineHeight: 14 * 1.2,
    },
    paragraphSmallBold: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        lineHeight: 14 * 1.2,
    },
    paragraphXS: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14, // TODO: this doesn't match the current design system spec
        lineHeight: 14 * 1.4,
    },
});

export const styles = StyleSheet.create({
    container: {
        height: Dimensions.get('window').height,
        alignItems: 'center',
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
        paddingBottom: 16,
    },
    containerButtons: {
        width: '100%',
        flex: 1,
        justifyContent: 'space-between',
    },
    containerHeader: {
        alignItems: 'center',
        textAlign: 'center',
        gap: 16,
    },
    containerHeaderText: {
        alignItems: 'center',
        gap: 12,
    },
    containerSplitInstructions: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8,
        alignSelf: 'stretch',
    },
    containerInstructionsWithMissingBannerButton: {
        gap: 12,
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    containerInstructions: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 12,
        gap: 12,
        borderRadius: 8,
        backgroundColor: color.alabaster,
        alignSelf: 'stretch',
    },
    containerMissingBannerButton: {
        borderBottomWidth: 1.5,
        borderBottomColor: 'rgba(0,0,0,0.16)',
    },
    containerCheckMark: {
        justifyContent: 'center',
        alignItems: 'center',
        height: normalizeHeight(20),
        width: normalizeHeight(20),
        borderRadius: normalizeHeight(20),
    },
    containerTosConsentButton: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        alignSelf: 'stretch',
    },
    containerCheckMarkSelected: {
        backgroundColor: color.emerald,
    },
    containerCheckMarkUnselected: {
        borderWidth: 2,
        borderColor: color.silverChalice,
    },
    containerWaitingScreen: {
        flex: 1,
        minHeight: '100lvh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerGreenCircleBackground: {
        height: normalizeHeight(300),
        width: normalizeHeight(300),
        borderRadius: normalizeHeight(300),
        backgroundColor: color.snowyMint,
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageCheckMarkIcon: {
        height: normalizeHeight(16),
        width: normalizeHeight(16),
        resizeMode: 'contain',
    },
    logoHeaderIcon: {
        width: 36,
        height: 36,
    },
    warningHeaderIcon: {
        width: 48,
        height: 48,
    },
    pointingEmoji: {
        position: 'absolute',
        right: normalizeWidth(24),
        top: 4,
        fontSize: 40,
        zIndex: 1,
    },
    body: {
        justifyContent: 'space-between',
        flexGrow: 1,
        alignSelf: 'stretch',
    },
});

export function getImageActivationPreambleStyle(region: Region) {
    return {
        height: normalizeHeight(region === Region.FR ? 204 : 172),
        width: normalizeHeight(325),
    };
}

export function containerStyleWithGap(gap: number) {
    return [styles.container, { gap } as ViewStyle];
}

export function normalizeHeight(size: number, ratioEffect: number = 1, maxSize?: number): number {
    const deviceSize = Dimensions.get('window').height;
    const standardDeviceHeight = 637; // standard for Figma wire frames in the revamped MSE onboarding flow
    return scaleToUserDeviceSize(size, deviceSize, standardDeviceHeight, ratioEffect, maxSize);
}

export function normalizeWidth(size: number, ratioEffect: number = 1, maxSize?: number): number {
    const deviceSize = Dimensions.get('window').width;
    const standardDeviceWidth = 375; // standard for Figma wire frames in the revamped MSE onboarding flow
    return scaleToUserDeviceSize(size, deviceSize, standardDeviceWidth, ratioEffect, maxSize);
}

function scaleToUserDeviceSize(
    size: number,
    deviceSize: number,
    standardDeviceSize: number,
    ratioEffect: number = 1,
    maxSize?: number
): number {
    const ratio = deviceSize / standardDeviceSize;
    const normalizedSize = Math.round(((ratio - 1) * ratioEffect + 1) * size);
    if (maxSize) return Math.min(normalizedSize, maxSize);
    else return normalizedSize;
}
